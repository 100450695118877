import axios from "axios";
import store from "@/store/store.js";
// import ip from '../ipBackend.js'
// const ip = 'http://localhost:5000'
const ip = 'https://apps.osbondgym.com/api'
// const ip = 'https://beosbondnew-28871855063.asia-southeast1.run.app'
// const ip = 'https://serova.id/api-osbond-dev'


// console.log('ip', ip)
// console.log('store.state.token', store.state.token)

const instance = axios.create({
  baseURL: ip,
});
instance.interceptors.request.use(async (req) => { 
// console.log('store.state.token', store.state.user_token)
  let token = store.state.user_token;
  // req.baseURL = 'http://localhost:5000'
  req.baseURL = ip
  req.headers.Authorization = `Bearer ${token}`
  // if (req.method == "post") {
  //   if (!req.data) req.data = {};
  //   //jika puskemas undefined / tidak terisi (bukan null)
  // }
  return req;
});

export default instance;
