<template>
    <Toast />
    <div class="login-body">
        <!-- <div class="login-image">
            <img :src="'layout/images/pages/login-'+ loginColor + '.png'" alt="atlantis">
        </div> -->
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img src="../assets/tagline-osbond.png" style="width: 320px;" class="login-logo"/>
                    <!-- <img :src="'layout/images/appname-'+ color + '.png'" class="login-appname"/> -->
                </div>
                <div class="form-container">
                    <!-- <div class="grid">
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText v-model="username" type="text" placeholder="Username" />
                        </InputGroup>
                    </div>
                    <div class="grid">
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-user"></i>
                            </InputGroupAddon>
                            <InputText v-model="username" type="text" placeholder="Username" />
                        </InputGroup>
                    </div> -->
                    <span class="p-input-icon-left">
                        <i class="pi pi-user"></i>
                        <InputText v-model="username" type="text" placeholder="Username" />
                    </span>
                    <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText v-model="password" type="password" placeholder="Password"/>
                    </span>
                    
                </div>
                <div class="button-container">
                    <Button @click="login" type="button" label="Login"></Button>
                </div>
            </div>

            <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <span>Rapier Technology</span>
                </div>
                <span>Copyright 2021</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
		return {
			username: '',
			password: '',
            eyes: true,
		}
	},
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        }
    },
    methods: {
        setType() {
            if (this.eyes) {
                return 'password';
            } else {
                return 'text';
            }
        },
        login(){
            let vm = this
            console.log(vm.$store.state.fcm_token, 'ini fcm token');
            
            // vm.$router.push({ path: "/dashboard" })
            vm.$axios.post("/user/login",{
                username: vm.username,
                password: vm.password
            })
            .then((res) => {
                if (res.data.message == "success" && res.data.data.length) {
                    console.log(res.data.data[0]);
                    let x = res.data.data[0];
                    this.$store.commit("set_token", x.token);
                    this.$store.commit("set_role", x.role);
                    this.$store.commit("set_club_admin", x.kode_club)
                    this.$store.commit("set_nama_admin", x.nama_user)
                    this.$store.commit("set_user_login", x)
                    this.$store.commit("set_url", 'https://minio.osbondgym.com/prd');
                    // this.$store.commit("set_url", 'http://localhost:5000');
                    this.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Login', life: 3000 })
                    // console.log(this.$store.state.user_token, 'ini token');
                    // if(this.$store.state.user_role == 'dinkes'){
                    //     setTimeout(() => {
                    //         vm.$router.push({ path: "/dinkes" });
                    //     }, 2000);
                    // }else{
                    //     setTimeout(() => {
                    //         vm.$router.push({ path: "/dashboard-puskesmas" });
                    //     }, 2000);
                    // }
                    setTimeout(() => {
                        vm.$router.push({ path: "/dashboard" });
                    }, 2000);
                } else {
                    this.$toast.add({ severity: 'danger', summary: 'Konfirmasi', detail: res.data.message, life: 3000 })
                }
            })
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'danger', summary: 'Konfirmasi', detail: 'GAGAL LOGIN', life: 3000 })
            })
        }
    }
}
</script>